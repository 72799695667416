import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { Card, Input, Form } from "antd";
import { Heading } from "./Heading";
import { SearchOutlined } from "@ant-design/icons";

export const Search = () => {
  const [barcodes, setBarcodes] = useState(null);
  const history = useHistory();

  const onSearch = () => {
    if (barcodes) {
      history.push({
        pathname: "/search",
        search: "?barcodes="+barcodes
      });
    }
  };

  return (
    <>
      <Heading title="Αναζήτηση αποστολών" />
      <Card>
        <h2>Αριθμός αποστολής</h2>
        <Input.Search
          value={barcodes}
          onChange={(e) => setBarcodes(e.target.value)}
          placeholder="Εισάγετε τους αριθμούς αποστολής"
          style={{ margin: "16px 0 30px 0" }}
          enterButton={<div><SearchOutlined /> Αναζήτηση</div>}
          onSearch={onSearch}
          size="large"
        />
        <p>
          * Μπορείτε να αναζητήσετε ταυτόχρονα περισσότερες από μια αποστολές
        </p>
      </Card>
    </>
  );
};
