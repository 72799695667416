import React from 'react'
import {Card, Spin, Result, Button} from 'antd'
import {Heading} from './Heading'
import {ArrowLeftOutlined} from '@ant-design/icons';


export const NotFound=({barcode})=>{
    return (
        <>
            <p style={{textAlign: "center", fontSize:"1.2em"}}>Ο αριθμός αποστολής <i style={{fontSize:"1.1em"}}>{barcode}</i> δεν βρέθηκε στο σύστημά μας</p>
            <Result
              status="404"
              subTitle="Ελέξτε για λάθη κατά την πληκτρολόγηση και δοκιμάστε μία νέα αναζήτηση"
            />
        </>
    )
}