import { Row, Col, Card, Tabs, Result, Button, Divider } from "antd";

export const Error = () => {
  return (
    <Result
      title="Συνέβη ένα σφάλμα"
      extra={<div>Δοκιμάστε μια νέα αναζήτηση</div>}
    />
  );
};
