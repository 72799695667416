import "./App.css";
import { Row, Col } from "antd";
import {Switch, Route, Redirect} from "react-router-dom";
import { Search } from "./components/Search";

import { SearchresultsList } from "./components/SearchresultsList";

export default function App() {
  return (
    <div className="App">
      <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
        <Col xs={24} sm={22} md={20} lg={18} xl={14} style={{maxWidth:"1000px"}}>
          <div style={{ padding: "10px" }}>
            <Switch>
                <Route path="/" exact><Search/></Route>
                <Route path="/search"><SearchresultsList/></Route>
                <Route path="*" ><Redirect to="/" /></Route>
            </Switch>
          </div>
        </Col>
      </Row>
    </div>
  );
}
