import React, { useState, useEffect } from "react";
import { Card, Tabs,  Button, Divider } from "antd";
import { Heading } from "./Heading";
import {Error} from './Error'
import { ArrowLeftOutlined } from "@ant-design/icons";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";

import { SearchResult } from "./Searchresult";
import { LoadingResults } from "./LoadingResults";

function useBarcodesQuery() {
  const { search } = useLocation();

  const searchParams = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );
  return searchParams.get("barcodes");
}

const { TabPane } = Tabs;

export const SearchresultsList = () => {
  let mounted =true;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [results, setResults] = useState([]);

  const history = useHistory();
  const barcodes = useBarcodesQuery();

  useEffect(() => {
    setLoading(true);

    axios.get(process.env.REACT_APP_BASE_URL + "/deliverytracking", {
        params: { barcodes: barcodes },
      }).then((response) => {
        if(mounted){
          setLoading(false);
          console.log(response.data)
          setResults(response.data);
        }
      }).catch((err) => {
        setLoading(false);
        setError(true)
      });

      return ()=>{ mounted = false;}
  }, [barcodes]);

  let content = null;

  if (loading) {
    content = <LoadingResults />;
  } else if (error || !results) {
    content = <Error/>;
  } else if (results.length == 1) {
    content = <SearchResult result={results[0]}/>;
  } else if (results.length > 1) {
    content = <Tabs>
      {
      results.map((_, i) => (
      <TabPane tab={<div style={{color:!_.exists?"crimson":null}}>{_.barcode}</div>} key={i}>
        <SearchResult key={i} result={_}/>
      </TabPane>))
      }
      </Tabs>
  }
  else{<Error/>};

  return (
    <>
      <Heading title="Αναζήτηση αποστολών" />
      <Card style={{ paddingTop: 0 }}>
        <h2>Αποτελέσματα αναζήτησης</h2>
        {content}
        <Divider />
        <Button type="primary" size="large" style={{ float: "right" }} onClick={()=>history.push("/")}>
          <ArrowLeftOutlined /> Νέα αναζήτηση
        </Button>
        <div style={{ clear: "both" }}></div>
      </Card>
    </>
  );
};
