import React from "react";
import { NotFound } from "./NotFound";
import { Timeline} from "antd";
import moment from "moment";
import { CheckCircleTwoTone } from '@ant-design/icons';

const printDate = (stringDate) => {
  const mom = moment(stringDate);
  mom.locale("el");
  return mom.format("DD/MM/YYYY HH:mm");
};

export const SearchResult = ({ result }) => {
  const successDot =     <CheckCircleTwoTone twoToneColor="#52c41a" style={{}}/>  ;
  return (
    <>
      {result && result.exists ? (
        <>
          <div style={{ fontSize: "1.2em",textAlign:"center", marginBottom:"20px" }}>
            Αριθμός αποστολής <span style={{marginLeft:"8px"}}>{result.barcode}</span>
          </div>
          <br/>
          <Timeline mode="left" style={{}}>
            {result.statuses.map((_, i) => (
              <Timeline.Item
              dot={_.description == 'ΑΠΟΣΤΟΛΗ ΠΑΡΑΔΟΘΗΚΕ'?successDot: null}
                label={
                  <div style={{ padding: ((result.statuses.length-1) == i ?"0 10px 0 10px":"0 10px 30px 10px") , fontSize:"1.1em"}}>
                    {printDate(_.timestamp)}
                  </div>
                }
              >
                <div style={{ padding: ((result.statuses.length-1) == i ?"0 10px 0 10px":"0 10px 30px 10px"), fontSize:"1.1em" }}>{_.description}</div>
              </Timeline.Item>
            ))}
          </Timeline>
        </>
      ) : (
        <NotFound barcode={result && result.barcode} />
      )}
    </>
  );
};
