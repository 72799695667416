import React from 'react'
import { Skeleton} from 'antd'
import {LoadingOutlined} from '@ant-design/icons';

export const LoadingResults=()=>{
    return (
        <>
            <p style={{ fontSize:"1.2em"}}>Φόρτωση δεδομένων ...</p>
          <Skeleton  active/>
        </>
    )
}